import React, { FunctionComponent, useMemo } from 'react';

import {
    BlackLink,
    BlogPostHeaderContainer,
    BlogPostHeaderContainerInner,
    Cover,
    CoverContainer,
    Date,
    Dates,
    DateStripe,
    InfoStripeContainer,
    IntroBlock,
    Title,
} from './style';
import { BlogPostHeaderProps } from './type';
import { Container } from '../../Container';
import { Copyright } from '../../Copyright';
import { formatDate } from '../../../lib/util';
import { BlogRecordImageType } from '../../../type';
import {isDateGreaterThan} from "../../../util";

export const BlogPostHeader: FunctionComponent<BlogPostHeaderProps> = ({
    data,
}) => {
    const headerImage = useMemo<BlogRecordImageType | undefined>(() => {
        return (data.frontmatter.images ?? []).find(
            (image: any) => image.isCover,
        );
    }, [data]);

    const { title, date, published, updatedAt } = data.frontmatter;

    const postedOn = formatDate(date);
    const updatedOn = data.parent.modifiedTime ?? postedOn;

    return (
        <BlogPostHeaderContainer>
            <BlogPostHeaderContainerInner>
                {!!(headerImage && headerImage.image) && (
                    <CoverContainer>
                        <Cover
                            image={
                                headerImage.image.childImageSharp
                                    .gatsbyImageData
                            }
                            alt="Avatar"
                        />
                    </CoverContainer>
                )}
                <IntroBlock>
                    <Container>
                        <Title>{title}</Title>
                    </Container>
                    <DateStripe>
                        <InfoStripeContainer>
                            <BlackLink
                                to={published ? '/blog' : '/blog-drafts'}
                            >
                                &larr; Back to list
                            </BlackLink>
                            <Dates>
                                <Date>Posted on {formatDate(date)}</Date>
                                {isDateGreaterThan(updatedOn, postedOn) && (
                                    <Date update>
                                        Last updated on {updatedOn}
                                    </Date>
                                )}
                            </Dates>
                        </InfoStripeContainer>
                    </DateStripe>
                </IntroBlock>
            </BlogPostHeaderContainerInner>
            {!!headerImage && (
                <Copyright
                    author={headerImage.author ?? ""}
                    source={headerImage.source ?? ""}
                    sourceText={headerImage.sourceText ?? ""}
                />
            )}
        </BlogPostHeaderContainer>
    );
};
